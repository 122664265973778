<footer class="ms-footer">
<div class="container">
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-6">
      <p class="color-white">All rights reserved. © GELS Pty Ltd 2021 | <small><a title ="iBlast.biz" href="https://iBlast.biz" class="noDecoration">Created by iBlast.biz</a></small></p>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6">
      <p class="text-right">
        <a href="#" data-toggle="modal" data-target="#Agreements" class="noDecoration">Agreements</a> | 
        <a href="#" data-toggle="modal" data-target="#Regulations" class="noDecoration">Rules & Regulations</a> | 
        <a href="#" data-toggle="modal" data-target="#Privacy" class="noDecoration">Privacy Policy</a>
      </p>
    </div>
  </div>            
</div>
</footer>