  <header class="ms-header ms-header-white">
  <div class="container container-full">
    <div class="ms-title">
      <a routerLink="/" routerLinkActive="active">
        <img [src]="nav.logo" alt="Que Sera Farm">
      </a>
    </div>
    <div class="header-right">
      <div class="share-menu">
        <ul class="share-menu-list">
          <li class="animated fadeInRight"><a href="https://www.facebook.com/pg/queserafarm/" target="_blank" class="btn-circle btn-facebook"><i class="fa fa-facebook"></i></a></li>
          <li class="animated fadeInRight"><a href="https://www.facebook.com/queserafarm/" target="_blank" class="btn-circle btn-instagram"><i class="fa fa-instagram"></i></a></li>
        </ul>
        <a class="btn-circle btn-circle-primary animated zoomInDown"><i class="fa fa-share-alt"></i></a>
      </div>

      <a class="btn-circle btn-circle-primary ms-toggle-left"><i class="fa fa-align-justify"></i></a>
    </div>
  </div>
  </header>

  <nav class="navbar navbar-expand-md  navbar-static ms-navbar ms-navbar-primary">
  <div class="container container-full">
    <div class="navbar-header">
      <a class="navbar-brand" routerLink="/" routerLinkActive="active">
        <img [src]="nav.logoScroll" alt="Que Sera Farm" class="resizeLogo">
      </a>
    </div>
    <div class="collapse navbar-collapse" id="ms-navbar">
      <ul class="navbar-nav">
        <!--
        <li class="nav-item dropdown">
          <a routerLink="/" routerLinkActive="active" class="nav-link">Home</a>
        </li>
        -->
        <li class="nav-item dropdown">
          <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" role="button" aria-haspopup="true" aria-expanded="false" data-name="facilities">Facilities <i class="fa fa-chevron-down"></i></a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" routerLink="/Facilities">Our Facilities</a></li>
            <li><a class="dropdown-item" routerLink="/FacilityStables">Safe Paddocks & Stables</a></li>
            <li><a class="dropdown-item" routerLink="/FacilityIndoorArena">Indoor Arena</a></li>
            <li><a class="dropdown-item" routerLink="/FacilityOutdoorArena">Outdoor Arena</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" role="button" aria-haspopup="true" aria-expanded="false" data-name="services">Services <i class="fa fa-chevron-down"></i></a>
          <ul class="dropdown-menu">
            <!--<li><a class="dropdown-item" routerLink="/ServiceAgistment">Agistment Regime</a></li>-->
            <li><a class="dropdown-item" routerLink="/ServiceArenas">Arena Hire</a></li>
            <li><a class="dropdown-item" routerLink="/ServiceEquitation">Equitation</a></li>
            <li><a class="dropdown-item" routerLink="/ServiceJumping">Jumping</a></li>
            <li><a class="dropdown-item" routerLink="/ServiceTrailRiding">Trail Riding</a></li>
            <li><a class="dropdown-item" routerLink="/Clinics">Clinics</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a routerLink="/prices" routerLinkActive="active" class="nav-link">Prices</a>
        </li>
        <li class="nav-item dropdown">
          <a routerLink="/horses-for-sale" routerLinkActive="active" class="nav-link">Horses for sale</a>
        </li>
        <li class="nav-item dropdown">
          <a routerLink="/RacehorseSpelling" routerLinkActive="active" class="nav-link">Racehorse Spelling</a>
        </li>
        <li class="nav-item dropdown">
          <a href="https://equistore.com.au" target="_blank" class="nav-link">Shop</a>
        </li>
        <li class="nav-item dropdown">
          <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" role="button" aria-haspopup="true" aria-expanded="false" data-name="gallery">Gallery <i class="fa fa-chevron-down"></i></a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" routerLink="/gallery-view">360 Tour View</a></li>
            <li><a class="dropdown-item" routerLink="/gallery-videos">Video Gallery</a></li>
            <li><a class="dropdown-item" routerLink="/gallery">Photo Gallery</a></li>
            <li><a class="dropdown-item" routerLink="/gallery-events">Events</a></li>
          </ul>
        </li>
        <!--
        <li class="nav-item dropdown">
          <a routerLink="/team" routerLinkActive="active" class="nav-link">Team</a>
        </li>
        -->
        <li class="nav-item dropdown">
          <a class="link" href="https://quesera.com.au/booking/" routerLinkActive="active">Booking</a>
        </li>
        <li class="nav-item dropdown">
          <a routerLink="/contact" routerLinkActive="active" class="nav-link">Contact us</a>
        </li>
      </ul>
    </div>
    <a class="ms-toggle-left btn-navbar-menu"><i class="fa fa-align-justify"></i></a>
  </div> 
  </nav>