<div class="index-1 intro-full-next pt-4">
  <div class="container">
    <div class="text-center mb-4">
      <h1 class="color-primary text-center" text-center>EQUITATION</h1>
    </div>
    <div class="row">
      <div class="col-lg-12 text-justify">
        <p>Equitation is designed to promote good horsemanship for riders by having their horses navigate a variety of challenging and unfamiliar obstacles. The Equitation course at Que Sera Farm consists of about sixteen obstacles including tunnels, gates and platforms. It is planned to be fun for riders from a basic level to the more experienced. The course is laid out so that riders may choose any obstacles they wish to avoid. Some photos are shown below:</p>
      </div>
    </div>
  </div>
</div>

  <div class="container mb-6">
    <div class="row">
      <div class="col-md-6">
      	<h1 class="color-primary right-line">EQUITATION RULES</h1>
        <ol class="service-list list-unstyled">
          <li>Equestrian activities are dangerous and a Waiver must be signed by all participants whether riding or working in hand. Waivers are available at the entrance to the course.</li>
          <li>All participants must wear correct helmet and gear</li>
          <li>Maximum of SIX horses allowed on the course together.</li>
          <li>ALL DOGS must be securely on a leash at all times.</li>
          <li>Children must be supervised at all times.</li>
          <li>Gate MUST be kept closed in the event that a horse gets loose.</li>
          <li>SAFETY FIRST relates to all aspects of riding on Que Sera Farm.</li>
          <li>If help required call 0408 477 799.</li>
        </ol>
      </div>
      <div class="col-md-6">
      	<h1 class="color-primary right-line">PRICES</h1>
        <p><b>$20 per hour (Inc GST)</b><br>Pricing for group bookings on request</p>
        <p>Use of the Equitation course is not included in any Que Sera agistment arrangement. Non-agistees may also hire the outdoor arena, jump circuit, indoor arena, horse walker, round yard , trail ride and day yards at current prices.</p>
        <div class="card card-primary-inverse mb-4 mt-4">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12 text-center">
                <h4 class="mt-lg-0">Bookings are essential</h4>
                <p>Please use the booking button on the Que Sera <a href="https://www.facebook.com/pg/queserafarm/" target="_blank" style="color: #FFF;">Facebook Page</a> or call the Stable no: 0408 477 799</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-6 mb-4">
    <div class="row">
      <div class="col-lg-12">
          <div id="carousel-example-generic" class="ms-carousel ms-carousel-thumb carousel slide mb-4" data-ride="carousel">
            <div class="card card-relative">
                <div class="carousel-inner" role="listbox">
                    <div class="carousel-item active">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/equitation-01.png" atl="QUE SERA FARM - Jumping">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/equitation-02.png" atl="QUE SERA FARM - Jumping">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/equitation-03.png" atl="QUE SERA FARM - Jumping">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/equitation-04.png" atl="QUE SERA FARM - Jumping">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/equitation-05.png" atl="QUE SERA FARM - Jumping">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/equitation-06.png" atl="QUE SERA FARM - Jumping">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/equitation-07.png" atl="QUE SERA FARM - Jumping">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/equitation-08.png" atl="QUE SERA FARM - Jumping">
                    </div>
                </div>
                <a href="#carousel-example-generic" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary left carousel-control-prev" role="button" data-slide="prev"><i class="zmdi zmdi-chevron-left"></i></a>
                <a href="#carousel-example-generic" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary right carousel-control-next" role="button" data-slide="next"><i class="zmdi zmdi-chevron-right"></i></a>
            </div>
            <ol class="carousel-indicators carousel-indicators-tumbs carousel-indicators-tumbs-outside">
                <li data-target="#carousel-example-generic" data-slide-to="0" class="active">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/equitation-01_thumb.png" atl="QUE SERA FARM - Jumping">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="1">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/equitation-02_thumb.png" atl="QUE SERA FARM - Jumping">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="2">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/equitation-03_thumb.png" atl="QUE SERA FARM - Jumping">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="3">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/equitation-04_thumb.png" atl="QUE SERA FARM - Jumping">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="4">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/equitation-05_thumb.png" atl="QUE SERA FARM - Jumping">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="5">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/equitation-06_thumb.png" atl="QUE SERA FARM - Jumping">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="6">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/equitation-07_thumb.png" atl="QUE SERA FARM - Jumping">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="7">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/equitation-08_thumb.png" atl="QUE SERA FARM - Jumping">
                </li>
            </ol>
          </div>
       </div>
    </div>
  </div>
<app-map-frame></app-map-frame>

