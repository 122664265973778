<div class="index-1 intro-full-next pt-4">
  <div class="container">
    <div class="text-center mb-4">
      <h1 class="color-primary text-center" text-center>ARENA HIRE</h1>
    </div>
    <div class="row">
      <div class="col-lg-12 text-justify">
        <p>Que Sera Farm has an Olympic sized 20 x 60 metre covered arena with Geopat – a superb European riding material. The arena has shadow-free lighting and ample seating for spectators on a viewing stand. The covered arena has sides that angle outwards and 12 dressage letters.   The arena may be hired for day or night events or clinics.</p>
        <p>In addition there is an outdoor grassed arena which may be booked hourly by appointment. This also has dressage letters.</p>
        <p>There is a large car park, wash bay, toilet facilities and limited wi-fi available for users. Day yards may also be hired at extra cost.</p>
        <p>Bookings are essential, please use the booking button on the Que Sera <a href="https://www.facebook.com/pg/queserafarm/" target="_blank">Facebook Page</a> or call the Stable no: 0408 477 799</p>
      </div>
    </div>
  </div>
</div>


  <div class="container">
    <div class="row">
      <div class="col-md-6">
      	<h1 class="color-primary right-line">ARENA RULES</h1>
        <ol class="service-list list-unstyled">
          <li>Equestrian activities are dangerous and a Waiver must be signed by all riders prior to riding. they are available at the entrance to the arena.</li>
          <li>All participants must wear correct helmet and gear.</li>
          <li>Maximum of four horses allowed in the arena at any one time. Riders must respect “right of way” rules.</li>
          <li>No dogs are allowed in the arena.</li>
          <li>Children must be supervised.</li>
          <li>Entrance -Gate MUST be kept closed at all times in accordance with insurance requirements.</li>
          <li>SAFETY FIRST relates to all aspects of riding on Que Sera Farm. If help required call 0408  477 799.</li>  
        </ol>
      </div>
      <div class="col-md-6">
      	<h1 class="color-primary right-line">PRICES</h1>
        <h3>Non Agisted Clients</h3>
        <ul class="">
          <li>Use of covered arena $20 per horse per hour or part thereof.</li>
          <li>Use of outdoor arena $10 per horse per hour or part thereof.</li>
          <li>Night time lighting for covered arena $8 per horse per hour or part thereof.</li>
        </ul>
        <h3>Group Bookings:</h3>
        <ul class="">
          <li>Full Day: $300</li>
          <li>Two consecutive days: $500</li>
        </ul>        
        <div class="card card-primary-inverse mb-4 mt-4">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12 text-center">
                <h4 class="mt-lg-0">Bookings are essential</h4>
                <p>Please use the booking button on the Que Sera <a href="https://www.facebook.com/pg/queserafarm/" target="_blank" style="color: #FFF;">Facebook Page</a> or call the Stable no: 0408 477 799</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-6 mb-4">
    <div class="row">
      <div class="col-lg-12">
          <div id="carousel-example-generic" class="ms-carousel ms-carousel-thumb carousel slide mb-4" data-ride="carousel">
            <div class="card card-relative">
                <div class="carousel-inner" role="listbox">
                    <div class="carousel-item active">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-Night.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-and-Grandstand.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Covered-Arena.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-01.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-02.jpg" atl="QUE SERA FARM - Arenas">
                    </div>

                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-03.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-04.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-05.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-06.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-07.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-08.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-09.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-10.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-11.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-06.jpg" atl="QUE SERA FARM - Outdoor Arena">
                    </div>
                </div>
                <a href="#carousel-example-generic" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary left carousel-control-prev" role="button" data-slide="prev"><i class="zmdi zmdi-chevron-left"></i></a>
                <a href="#carousel-example-generic" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary right carousel-control-next" role="button" data-slide="next"><i class="zmdi zmdi-chevron-right"></i></a>
            </div>
            <ol class="carousel-indicators carousel-indicators-tumbs carousel-indicators-tumbs-outside">
                <li data-target="#carousel-example-generic" data-slide-to="0" class="active">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-Night_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="1">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-and-Grandstand_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="2">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Covered-Arena_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="3">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-01_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="4">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-02_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="5">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-03_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="6">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-04_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="7">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-05_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="8">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-06_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="9">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-07_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="10">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-08_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="11">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-09_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="12">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-10_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="13">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-11_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="14">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-06_thumb.jpg" atl="QUE SERA FARM - Outdoor Arena">
                </li>
            </ol>
          </div>
       </div>
    </div>
  </div>

 <app-map-frame></app-map-frame>