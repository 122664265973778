<div class="container">
  <div class="row">
    <div class="col-lg mb-2 text-center">
        <h1 class="color-primary text-center">OUTDOOR ARENA</h1>
        <p>The outdoor arena is full sized with dressage letters. There is an adjacent warm up area.</p>
    </div>
  </div>
</div>
<div class="container mt-6 mb-4">
    <div class="row">
      <div class="col-lg-12">
          <div id="carousel-example-generic" class="ms-carousel ms-carousel-thumb carousel slide mb-4" data-ride="carousel">
            <div class="card card-relative">
                <div class="carousel-inner" role="listbox">
                    <div class="carousel-item active">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-06.jpg" atl="QUE SERA FARM - Outdoor Arena">
                    </div>
                </div>
                <a href="#carousel-example-generic" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary left carousel-control-prev" role="button" data-slide="prev"><i class="zmdi zmdi-chevron-left"></i></a>
                <a href="#carousel-example-generic" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary right carousel-control-next" role="button" data-slide="next"><i class="zmdi zmdi-chevron-right"></i></a>
            </div>
            <ol class="carousel-indicators carousel-indicators-tumbs carousel-indicators-tumbs-outside">
                <li data-target="#carousel-example-generic" data-slide-to="0" class="active">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-06_thumb.jpg" atl="QUE SERA FARM - Outdoor Arena">
                </li>
            </ol>
          </div>
       </div>
    </div>
</div>
