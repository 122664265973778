<div class="index-1 intro-full-next pt-4">
  <div class="container">
    <div class="text-center mb-4">
      <h1 class="color-primary text-center" text-center>TRAIL RIDING</h1>
    </div>
    <div class="row">
      <div class="col-lg-12 text-justify">
        <p>Que Sera Farm has two trail rides in the scenic wooded Biddaddaba valley  – a short course for relatively inexperienced riders and a longer 2 km trail with uphill and downhill stretches, creek beds and jumps.</p>
      </div>
    </div>
  </div>
</div>

  <div class="container">
    <div class="row">
      <div class="col-md-6">
      	<h1 class="right-line">RULES</h1>
        <ol class="service-list list-unstyled">
          <li>Follow white arrows for direction</li>
          <li>Ride on the trail path and cleared areas only</li>
          <li>Be aware of wild life</li>
          <li>Equestrian activities are dangerous and a Waiver must be signed by all riders prior to riding. They are available at the entrance to the trail.arena.</li>
          <li>All participants must wear correct helmet and gear.</li>
          <li>Maximum of four horses allowed to ride together.</li>
          <li>No dogs are allowed to accompany riders.</li>
          <li><strong>SAFETY FIRST</strong> relates to all aspects of riding on Que Sera Farm. If help required call 0408 477 799.</li>
        </ol>
      </div>
      <div class="col-md-6">
      	<h1 class="right-line">PRICES</h1>
        <ul class="">
          <li>$10 per horse per circuit of the trail</li>
          <li>Agistees may use the trail circuit free of charge.</li>
          <li>Pricing for group bookings on request</li>
        </ul>
        <div class="card card-primary-inverse mb-4 mt-4">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12 text-center">
                <h4 class="mt-lg-0">Bookings are essential</h4>
                <p>Please use the booking button on the Que Sera <a href="https://www.facebook.com/pg/queserafarm/" target="_blank" style="color: #FFF;">Facebook Page</a> or call the Stable no: 0408 477 799</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-6 mb-4">
    <div class="row">
      <div class="col-lg-12">
          <div id="carousel-example-generic" class="ms-carousel ms-carousel-thumb carousel slide mb-4" data-ride="carousel">
            <div class="card card-relative">
                <div class="carousel-inner" role="listbox">
                    <div class="carousel-item active">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/trailRiding-01.jpg" atl="QUE SERA FARM - Trail Riding">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/trailRiding-02.jpg" atl="QUE SERA FARM - Trail Riding">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/trailRiding-03.jpg" atl="QUE SERA FARM - Trail Riding">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/trailRiding-04.jpg" atl="QUE SERA FARM - Trail Riding">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/trailRiding-05.jpg" atl="QUE SERA FARM - Trail Riding">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/trailRiding-06.jpg" atl="QUE SERA FARM - Trail Riding">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/trailRiding-07.jpg" atl="QUE SERA FARM - Trail Riding">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/trailRiding-08.jpg" atl="QUE SERA FARM - Trail Riding">
                    </div>
                </div>
                <a href="#carousel-example-generic" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary left carousel-control-prev" role="button" data-slide="prev"><i class="zmdi zmdi-chevron-left"></i></a>
                <a href="#carousel-example-generic" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary right carousel-control-next" role="button" data-slide="next"><i class="zmdi zmdi-chevron-right"></i></a>
            </div>
            <ol class="carousel-indicators carousel-indicators-tumbs carousel-indicators-tumbs-outside">
                <li data-target="#carousel-example-generic" data-slide-to="0" class="active">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/trailRiding-01_thumb.jpg" atl="QUE SERA FARM - Trail Riding">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="1">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/trailRiding-02_thumb.jpg" atl="QUE SERA FARM - Trail Riding">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="2">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/trailRiding-03_thumb.jpg" atl="QUE SERA FARM - Trail Riding">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="3">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/trailRiding-04_thumb.jpg" atl="QUE SERA FARM - Trail Riding">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="4">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/trailRiding-05_thumb.jpg" atl="QUE SERA FARM - Trail Riding">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="5">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/trailRiding-06_thumb.jpg" atl="QUE SERA FARM - Trail Riding">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="6">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/trailRiding-07_thumb.jpg" atl="QUE SERA FARM - Trail Riding">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="7">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/trailRiding-08_thumb.jpg" atl="QUE SERA FARM - Trail Riding">
                </li>
            </ol>
          </div>
       </div>
    </div>
  </div>
  
<app-map-frame></app-map-frame>

  