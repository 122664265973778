import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery-videos',
  templateUrl: './gallery-videos.component.html',
  styleUrls: ['./gallery-videos.component.css']
})
export class GalleryVideosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
