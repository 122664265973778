<div class="btn-back-top">
	<a href="#" data-scroll id="back-top" class="btn-circle btn-circle-primary btn-circle-sm btn-circle-raised "><i class="fa fa-arrow-up"></i></a>
</div>

<div class="modal modal-primary" id="Privacy" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog modal-lg animated zoomIn animated-3x" role="document">
    <div class="modal-content">
      <div class="modal-header d-block shadow-2dp no-pb">
        <button type="button" class="close d-inline pull-right mt-2" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fa fa-times"></i></span></button>
        <div class="modal-title text-center">
          <img src="https://quesera.s3.amazonaws.com/logo_ribbon_250_black.png" alt="QueSera Farm" style="height: 35px;">
        </div>
      </div>
      <div class="modal-body">
          <h1 class="color-primary text-center center-block ms-site-title-lg mt-2 mb-2 zoomInDown">Privacy Policy</h1>
          <p>This policy covers how Gemini Equestrian & Livestock Services Pty Ltd.(“GELS”), the operator of Que Sera Farm and Equistore treats personal information that we collect and receive, including information related to your past use of our website and website services. Personal information is information about you that is personally identifiable like your name, address, email address, or phone number, and that is not otherwise publicly available.</p>
          <h3 class="color-primary">Terms Of Use</h3>
          <p>This Web site is provided by GELS . By visiting this site, you agree to abide by the terms and conditions set forth in this notice. If you do not agree to abide by these terms and conditions, do not use the site.</p>
          <p>Subject to the terms and conditions set forth in this agreement, GELS grants you a non-exclusive, non-transferable, limited license and right to access the materials contained herein. You agree not to interrupt or attempt to interrupt the operation of this Web site in any way.</p>
          <p>GELS authorizes you to only view the information on this Web site. GELS does not authorize you, and you are strictly forbidden, to copy information from this site to use, publicly display, or distribute or otherwise use for any commercial purpose without the expressed written permission of a director of GELS the operator of this website.</p>
          <p>Furthermore, the GELS website contains links to other websites. By visiting those sites, you agree to abide by the terms and conditions set forth in the respective notices of those sites.</p>
          <h3 class="color-primary">General</h3>
          <p>GELS automatically receives and records information on our server logs from your browser, including your IP address, cookie information, and the page you request.</p>
          <p>GELS uses information for the following general purposes: to customize the advertising and content you see, fulfill your requests for products and services, improve our services, contact you, conduct research, and provide anonymous reporting for internal and external clients.</p>
          <h3 class="color-primary">Information Sharing & Disclosure</h3>
          <p>GELS does not rent, sell, or share personal information about you with other people or non-affiliated companies except to provide products or services you’ve requested, when we have your permission.</p>
          <p>Information and images found on this website may not be reproduced without consent from GELS.</p>
          <h3 class="color-primary">Cookies</h3>
          <p>Most non-personal information is collected via cookies. GELS may use cookies to enhance the user experience and personalise content. A cookie is a small data file that is transferred to your hard drive and identifies you as a unique user. Cookies help us understand which areas of the website are most popular, so we can improve the user experience on future visits.</p>
          <h3 class="color-primary">Confidentiality & Security</h3>
          <p>We limit access to personal information about you to employees who we believe reasonably need to come into contact with that information to provide products or services to you or in order to do their jobs.</p>
          <p>We have physical, electronic, and procedural safeguards that comply with federal regulations to protect personal information about you.</p>
          <h3 class="color-primary">Changes To This Privacy Policy</h3>
          <p>GELS may update this policy from time to time.</p>
          <h3 class="color-primary">Contact Us</h3>
          <p>If you have any concerns or questions relating to your privacy while using our website.</p>
      </div>
    </div>
  </div>
</div>

<div class="modal modal-primary" id="Regulations" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog modal-lg animated zoomIn animated-3x" role="document">
    <div class="modal-content">
      <div class="modal-header d-block shadow-2dp no-pb">
        <button type="button" class="close d-inline pull-right mt-2" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fa fa-times"></i></span></button>
        <div class="modal-title text-center">
          <img src="https://quesera.s3.amazonaws.com/logo_ribbon_250_black.png" alt="QueSera Farm" style="height: 35px;">
        </div>
      </div>
      <div class="modal-body">
        <h1 class="color-primary text-center center-block ms-site-title-lg mt-2 mb-2 zoomInDown">Rules and Regulations</h1>
        <p>All invitees are required to observe rules on Que Sera Farm and all riders must sign a waiver and wear appropriate safety gear. We recommend horses should be Hendra virus vaccinated.</p>
        <p>Que Sera is a working farm and no loose dogs are permitted.</p>
      </div>
    </div>
  </div>
</div>

<div class="modal modal-primary" id="Agreements" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog modal-lg animated zoomIn animated-3x" role="document">
    <div class="modal-content">
      <div class="modal-header d-block shadow-2dp no-pb">
        <button type="button" class="close d-inline pull-right mt-2" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fa fa-times"></i></span></button>
        <div class="modal-title text-center">
          <img src="https://quesera.s3.amazonaws.com/logo_ribbon_250_black.png" alt="QueSera Farm" style="height: 35px;">
        </div>
      </div>
      <div class="modal-body">
        <h1 class="color-primary text-center center-block ms-site-title-lg mt-2 mb-2 zoomInDown">Agreements</h1>
        <p>Our standard Agreements reflect the situation in law but each one is tailor made to suit the requirements of our clients and their horses.</p>
        <p>Our standard Contract must be completed before horses are accepted for Training and Agistment with us.</p>
        <p>Please take the time to read our privacy statement and contact us should you have any questions.</p>
        <p>We accept payment by cash, cheque, bank transfer or EFTPOS (farm only).</p>
        <p>We also take American Express, Master Card and Visa.</p>
        <p>We look forward to hearing from you and please do not hesitate to ask for any specific equestrian needs that you might have.</p>
        <p>Kay Sutherland will be delighted to answer your questions.</p>
        <p>Price guidelines may also be requested from Kay Sutherland for Lessons, Agistment or Training.</p>
        <p>Please contact us if you would like to receive notice of Que Sera events such as Clinics and Competitions.</p>
      </div>
    </div>
  </div>
</div>
