import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facilities',
  templateUrl: './facilities.component.html',
  styleUrls: ['./facilities.component.css']
})
export class FacilitiesComponent implements OnInit {

	home = {
		videoDrone: 'https://quesera.s3.amazonaws.com/quesera_drone.mp4',
		logo: 'https://quesera.s3.amazonaws.com/favicon_140.png'
	};

  constructor() { }

  ngOnInit(): void {
  }

}
