<div class="container">
  <div class="row">
    <div class="col-lg mb-2 text-center">
        <h1 class="color-primary text-center">JUMPING, EQUITATION & TRAIL RIDING</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-6 col-sm-6 mb-2">
      <div class="ms-icon-feature">
        <div class="ms-icon-feature-icon">
          <span class="ms-icon ms-icon-lg ms-icon-inverse"><img src="https://quesera.s3.amazonaws.com/horseshoe.png" alt="Que Sera Farm" style="width: 30px; height: 30px;"></span>
        </div>
        <div class="ms-icon-feature-content">
          <h4 class="color-primary">EQUITATION</h4>
          <p class="text-justify">Equitation is a fun obstacle course designed to test riders and horses. The course consists of around 12 challenges which riders can choose to attempt with more planned.</p>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-6 mb-2">
      <div class="ms-icon-feature">
        <div class="ms-icon-feature-icon">
          <span class="ms-icon ms-icon-lg ms-icon-inverse"><img src="https://quesera.s3.amazonaws.com/horseshoe.png" alt="Que Sera Farm" style="width: 30px; height: 30px;"></span>
        </div>
        <div class="ms-icon-feature-content">
          <h4 class="color-primary">TRAIL RIDE</h4>
          <p class="text-justify">Covered grandstand and individual seats.</p>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-6 mb-2">
      <div class="ms-icon-feature">
        <div class="ms-icon-feature-icon">
          <span class="ms-icon ms-icon-lg ms-icon-inverse"><img src="https://quesera.s3.amazonaws.com/horseshoe.png" alt="Que Sera Farm" style="width: 30px; height: 30px;"></span>
        </div>
        <div class="ms-icon-feature-content">
          <h4 class="color-primary">JUMP CIRCUIT</h4>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container mt-6 mb-4">
  <div class="row">
    <div class="col-lg-12">
        <div id="carousel-example-generic" class="ms-carousel ms-carousel-thumb carousel slide mb-4" data-ride="carousel">
          <div class="card card-relative">
              <div class="carousel-inner" role="listbox">
                  <div class="carousel-item active">
                      <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-01.jpg" atl="QUE SERA FARM - Jumping">
                  </div>
                  <div class="carousel-item">
                      <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-02.jpg" atl="QUE SERA FARM - Jumping">
                  </div>
                  <div class="carousel-item">
                      <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-04.jpg" atl="QUE SERA FARM - Jumping">
                  </div>
                  <div class="carousel-item">
                      <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-05.jpg" atl="QUE SERA FARM - Jumping">
                  </div>
                  <div class="carousel-item">
                      <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-06.jpg" atl="QUE SERA FARM - Jumping">
                  </div>
                  <div class="carousel-item">
                      <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-07.jpg" atl="QUE SERA FARM - Jumping">
                  </div>
                  <div class="carousel-item">
                      <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-08.jpg" atl="QUE SERA FARM - Jumping">
                  </div>
                  <div class="carousel-item">
                      <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-09.jpg" atl="QUE SERA FARM - Jumping">
                  </div>
                  <div class="carousel-item">
                      <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-10.jpg" atl="QUE SERA FARM - Jumping">
                  </div>
                  <div class="carousel-item">
                      <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-03.jpg" atl="QUE SERA FARM - Jumping">
                  </div>
                  <div class="carousel-item">
                      <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-04.jpg" atl="QUE SERA FARM - Jumping">
                  </div>
              </div>
              <a href="#carousel-example-generic" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary left carousel-control-prev" role="button" data-slide="prev"><i class="zmdi zmdi-chevron-left"></i></a>
              <a href="#carousel-example-generic" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary right carousel-control-next" role="button" data-slide="next"><i class="zmdi zmdi-chevron-right"></i></a>
          </div>
          <ol class="carousel-indicators carousel-indicators-tumbs carousel-indicators-tumbs-outside">
              <li data-target="#carousel-example-generic" data-slide-to="0" class="active">
                  <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-01_thumb.jpg" atl="QUE SERA FARM - Jumping">
              </li>
              <li data-target="#carousel-example-generic" data-slide-to="1">
                  <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-02_thumb.jpg" atl="QUE SERA FARM - Jumping">
              </li>
              <li data-target="#carousel-example-generic" data-slide-to="2">
                  <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-04_thumb.jpg" atl="QUE SERA FARM - Jumping">
              </li>
              <li data-target="#carousel-example-generic" data-slide-to="3">
                  <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-05_thumb.jpg" atl="QUE SERA FARM - Jumping">
              </li>
              <li data-target="#carousel-example-generic" data-slide-to="4">
                  <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-06_thumb.jpg" atl="QUE SERA FARM - Jumping">
              </li>
              <li data-target="#carousel-example-generic" data-slide-to="5">
                  <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-07_thumb.jpg" atl="QUE SERA FARM - Jumping">
              </li>
              <li data-target="#carousel-example-generic" data-slide-to="6">
                  <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-08_thumb.jpg" atl="QUE SERA FARM - Jumping">
              </li>
              <li data-target="#carousel-example-generic" data-slide-to="7">
                  <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-09_thumb.jpg" atl="QUE SERA FARM - Jumping">
              </li>
              <li data-target="#carousel-example-generic" data-slide-to="8">
                  <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-10_thumb.jpg" atl="QUE SERA FARM - Jumping">
              </li>
              <li data-target="#carousel-example-generic" data-slide-to="9">
                  <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-03_thumb.jpg" atl="QUE SERA FARM - Jumping">
              </li>
              <li data-target="#carousel-example-generic" data-slide-to="10">
                  <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-04_thumb.jpg" atl="QUE SERA FARM - Jumping">
              </li>
          </ol>
        </div>
     </div>
  </div>
</div>