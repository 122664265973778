<div class="container">
<div class="row">
  <div class="col-lg mb-2 text-center">
    <h1 class="color-primary text-center">INDOOR ARENA</h1>
  </div>
</div>
<div class="row">
  <div class="col-lg-4 col-md-6 col-sm-6 mb-2">
    <div class="ms-icon-feature">
      <div class="ms-icon-feature-icon">
        <span class="ms-icon ms-icon-lg ms-icon-inverse"><img src="https://quesera.s3.amazonaws.com/horseshoe.png" alt="Que Sera Farm" style="width: 30px; height: 30px;"></span>
      </div>
      <div class="ms-icon-feature-content">
        <h4 class="color-primary">FULLY COVERED OLYMPIC SIZE</h4>
        <p class="text-justify">Laid back fencing constructed in Marine ply (20m x 60m)</p>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-6 mb-2">
    <div class="ms-icon-feature">
      <div class="ms-icon-feature-icon">
        <span class="ms-icon ms-icon-lg ms-icon-inverse"><img src="https://quesera.s3.amazonaws.com/horseshoe.png" alt="Que Sera Farm" style="width: 30px; height: 30px;"></span>
      </div>
      <div class="ms-icon-feature-content">
        <h4 class="color-primary">AMPLE SEATING FOR SPECTATORS</h4>
        <p class="text-justify">Covered grandstand and individual seats.</p>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-6 mb-2">
    <div class="ms-icon-feature">
      <div class="ms-icon-feature-icon">
        <span class="ms-icon ms-icon-lg ms-icon-inverse"><img src="https://quesera.s3.amazonaws.com/horseshoe.png" alt="Que Sera Farm" style="width: 30px; height: 30px;"></span>
      </div>
      <div class="ms-icon-feature-content">
        <h4 class="color-primary">BRIGHT SHADOW-FREE LED LIGHTING</h4>
        <p class="text-justify">The surface was replaced in January 2018 with European ‘Geopat’ a superb riding material</p>
      </div>
      </div>
    </div>
  </div>
</div>

<div class="container mt-6 mb-4">
    <div class="row">
      <div class="col-lg-12">
          <div id="carousel-example-generic" class="ms-carousel ms-carousel-thumb carousel slide mb-4" data-ride="carousel">
            <div class="card card-relative">
                <div class="carousel-inner" role="listbox">
                    <div class="carousel-item active">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-Night.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-and-Grandstand.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Covered-Arena.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-01.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-02.jpg" atl="QUE SERA FARM - Arenas">
                    </div>

                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-03.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-04.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-05.jpg" atl="QUE SERA FARM - Arenas">
                    </div>

                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-06.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-07.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-08.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-09.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-10.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Arena-11.jpg" atl="QUE SERA FARM - Arenas">
                    </div>
                </div>
                <a href="#carousel-example-generic" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary left carousel-control-prev" role="button" data-slide="prev"><i class="zmdi zmdi-chevron-left"></i></a>
                <a href="#carousel-example-generic" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary right carousel-control-next" role="button" data-slide="next"><i class="zmdi zmdi-chevron-right"></i></a>
            </div>
            <ol class="carousel-indicators carousel-indicators-tumbs carousel-indicators-tumbs-outside">
                <li data-target="#carousel-example-generic" data-slide-to="0" class="active">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-Night_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="1">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-and-Grandstand_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="2">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Covered-Arena_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="3">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-01_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="4">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-02_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="5">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-03_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="6">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-04_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="7">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-05_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="8">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-06_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="9">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-07_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="10">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-08_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="11">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-09_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="12">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-10_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="13">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Arena-11_thumb.jpg" atl="QUE SERA FARM - Arena">
                </li>
            </ol>
          </div>
       </div>
    </div>
</div>