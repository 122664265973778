<div class="index-1 intro-full-next pt-4">
  <div class="container">
    <div class="text-center mb-4">
      <h1 class="color-primary text-center" text-center>CLINICS</h1>
    </div>
    <div class="row">
      <div class="col-lg-12 text-justify">
        <p>Que Sera Farm hosts many Dressage Clinics of one to three days duration featuring top equestrian coaches and also Liberty Clinics</p>
        <p>Que Sera Farm hosts many clinics of one to three days duration featuring top equestrian coaches. Sessions take place in our excellent covered arena which has seating for spectators. There is ample parking for floats and trucks and ten day yards and a wash bay are available. There are toilet facilities at Que Sera and limited wi-fi coverage.  We plan to offer Jump Clinics in the future.</p>
        <p>Coaches interested in organising an event at Que Sera should contact Kay Sutherland on 0429 810 668.</p>
      </div>
    </div>
  </div>
</div>

<!--
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
            <div class="ms-thumbnail-container">
                <img src="https://quesera.s3.amazonaws.com/_elliot_clinic.png" atl="QUE SERA FARM - Clinics" class="img-fluid">
            </div>
        </div>
    </div>
  </div>
-->
  <div class="container mt-6 mb-4">
    <div class="row">
      <div class="col-lg-12">
          <div id="carousel-example-generic" class="ms-carousel ms-carousel-thumb carousel slide mb-4" data-ride="carousel">
            <div class="card card-relative">
                <div class="carousel-inner" role="listbox">
                    <div class="carousel-item active">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Clinics-01.jpg" atl="QUE SERA FARM - Clinics">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Clinics-02.jpg" atl="QUE SERA FARM - Clinics">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Clinics-03.jpg" atl="QUE SERA FARM - Clinics">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Clinics-04.jpg" atl="QUE SERA FARM - Clinics">
                    </div>
                </div>
                <a href="#carousel-example-generic" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary left carousel-control-prev" role="button" data-slide="prev"><i class="zmdi zmdi-chevron-left"></i></a>
                <a href="#carousel-example-generic" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary right carousel-control-next" role="button" data-slide="next"><i class="zmdi zmdi-chevron-right"></i></a>
            </div>
            <ol class="carousel-indicators carousel-indicators-tumbs carousel-indicators-tumbs-outside">
                <li data-target="#carousel-example-generic" data-slide-to="0" class="active">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Clinics-01_thumb.jpg" atl="QUE SERA FARM - Clinics">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="1">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Clinics-02_thumb.jpg" atl="QUE SERA FARM - Clinics">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="2">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Clinics-03_thumb.jpg" atl="QUE SERA FARM - Clinics">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="3">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Clinics-04_thumb.jpg" atl="QUE SERA FARM - Clinics">
                </li>
            </ol>
          </div>
       </div>
    </div>
  </div>

<app-map-frame></app-map-frame>
