import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ContactComponent } from './contact/contact.component';
//import { ServiceAgistmentComponent } from './service-agistment/service-agistment.component';
import { ServiceArenasComponent } from './service-arenas/service-arenas.component';
import { ServiceEquitationComponent } from './service-equitation/service-equitation.component';
import { ServiceJumpingComponent } from './service-jumping/service-jumping.component';
import { ServiceTrailRidingComponent } from './service-trail-riding/service-trail-riding.component';
import { ClinicsComponent } from './clinics/clinics.component';
import { HorsesForSaleComponent } from './horses-for-sale/horses-for-sale.component';

import { FacilityStablesComponent } from './facility-stables/facility-stables.component';
import { FacilityIndoorArenaComponent } from './facility-indoor-arena/facility-indoor-arena.component';
import { FacilityOutdoorArenaComponent } from './facility-outdoor-arena/facility-outdoor-arena.component';
import { FacilityJumpingComponent } from './facility-jumping/facility-jumping.component';
import { FacilitiesComponent } from './facilities/facilities.component';
import { PricesComponent } from './prices/prices.component';
import { GalleryViewComponent } from './gallery-view/gallery-view.component';
import { GalleryVideosComponent } from './gallery-videos/gallery-videos.component';
import { GalleryEventsComponent } from './gallery-events/gallery-events.component';
import { TeamComponent } from './team/team.component';
import { SocialComponent } from './social/social.component';
import { BookingComponent } from './booking/booking.component';
import { RacehorseSpellingComponent } from './racehorse-spelling/racehorse-spelling.component';

const routes: Routes =[
    { path: '', component: HomeComponent },
    { path: '**', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', redirectTo: '', pathMatch: 'full' },
    { path: 'gallery', component: GalleryComponent },
    { path: 'gallery-view', component: GalleryViewComponent },
    { path: 'gallery-videos', component: GalleryVideosComponent },
    { path: 'gallery-events', component: GalleryEventsComponent },
    { path: 'contact', component: ContactComponent },
    //{ path: 'ServiceAgistment', component: ServiceAgistmentComponent },
    { path: 'ServiceArenas', component: ServiceArenasComponent },
    { path: 'ServiceEquitation', component: ServiceEquitationComponent },
    { path: 'ServiceJumping', component: ServiceJumpingComponent },
    { path: 'ServiceTrailRiding', component: ServiceTrailRidingComponent },
    { path: 'Clinics', component: ClinicsComponent },
    { path: 'horses-for-sale', component: HorsesForSaleComponent },
    { path: 'FacilityStables', component: FacilityStablesComponent },
    { path: 'FacilityIndoorArena', component: FacilityIndoorArenaComponent },
    { path: 'FacilityOutdoorArena', component: FacilityOutdoorArenaComponent },
    { path: 'FacilityJumping', component: FacilityJumpingComponent },
    { path: 'Facilities', component: FacilitiesComponent },
    { path: 'prices', component: PricesComponent },
    { path: 'team', component: TeamComponent },
    { path: 'social', component: SocialComponent },
    { path: 'booking', component: BookingComponent },
    { path: 'RacehorseSpelling', component: RacehorseSpellingComponent }
];

@NgModule({
  //imports: [RouterModule.forRoot(routes)],
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled', // Add options right here
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
