<section class="mt-4">
  <div class="container">
    <div class="row d-flex justify-content-center" *ngIf="arrHorses">

      <div class="col-xl-4 col-lg-4 col-md-4" *ngFor="let horse of arrHorses">
        <div class="card mt-4 card-primary">
          <div style="background-image: url({{horse.image1}}); background-size: cover; background-position: center center">
            <br /><br /><br />
            <img src="{{horse.image2}}" atl="{{horse.Name}}" class="img-avatar-circle">
          </div>
          <div class="card-body pt-6 text-center">
            <p class="lead">{{horse.Name}}</p>
            <!--
            <button type="button" data-toggle="modal" data-target="#modal{{horse.slug}}" class="btn btn-success btn-raised">View details</button>
            -->
            <button type="button" class="btn btn-success btn-raised" data-toggle="modal" (click)="Setvideourl(horse.slug)" [attr.data-target]="'#myModal' + horse.slug">View details</button>

            <a href="{{horse.specPDF}}" target="_blank" class="btn btn-success btn-raised">Download PDF</a>

          </div>
        </div>


        <div class="modal modal-primary" [attr.id]="'myModal' + horse.slug" tabindex="-1" role="dialog">
          <div class="modal-dialog modal-lg animated zoomIn animated-3x" role="document">
            <div class="modal-content">
              <div class="modal-header d-block shadow-2dp no-pb">
                <button type="button" class="close d-inline pull-right mt-2" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="zmdi zmdi-close"></i></span></button>
                <div class="modal-title text-center">
                  <img src="https://quesera.s3.amazonaws.com/logo_ribbon_250_black.png" alt="QueSera Farm" style="height: 35px;">
                </div>
              </div>
              <div class="modal-body">
                <h1 class="text-center">{{horse.Name}}</h1>

                <div *ngIf="horse.video!=''">

                  <!-- <embed
                  src={{Videourl}}
                  wmode="transparent"
                  type="video/mp4"
                  width="100%" height="100%"
                  allow="autoplay; encrypted-media; picture-in-picture"
                  allowfullscreen
                  title="Horse - Cognac"
                  > -->
                  <iframe width="100%" height="300" [src]="Videourl|safe"></iframe>`
                  </div>

                  <div class="table-responsive">
                    <table class="table table-bordered table-striped">
                      <colgroup>
                        <col class="col-xs-1">
                        <col class="col-xs-7">
                      </colgroup>
                      <tbody>
                        <tr>
                          <td>
                            Name:
                          </td>
                          <td>{{horse.Name}}</td>
                        </tr>
                        <tr>
                          <td>
                            Breed:
                          </td>
                          <td>{{horse.Breed}}</td>
                        </tr>
                        <tr>
                          <td>
                            Foal Date:
                          </td>
                          <td>{{horse.FoalDate}}</td>
                        </tr>
                        <tr>
                          <td>
                            Height:
                          </td>
                          <td>{{horse.Height}}</td>
                        </tr>
                        <tr>
                          <td>
                            Sex:
                          </td>
                          <td>{{horse.Sex}}</td>
                        </tr>
                        <tr>
                          <td>
                            Colour:
                          </td>
                          <td>{{horse.Colour}}</td>
                        </tr>
                        <tr>
                          <td>
                            Sire:
                          </td>
                          <td>{{horse.Sire}}</td>
                        </tr>
                        <tr>
                          <td>
                            Dam:
                          </td>
                          <td>{{horse.Dam}}</td>
                        </tr>
                        <tr>
                          <td>
                            Price:
                          </td>
                          <td>{{horse.Price}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-lg-12">
                      <div class="ms-thumbnail-container">
                        <img src="{{horse.specsheet}}" atl="{{horse.Name}}" class="img-fluid">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

    </div>
  </div>
</section>

