import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facility-stables',
  templateUrl: './facility-stables.component.html',
  styleUrls: ['./facility-stables.component.css']
})
export class FacilityStablesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
