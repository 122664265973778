import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from "@angular/router";
import { switchMap } from "rxjs/operators"
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import horseDetailsData from '../Model/HorseDetails';
@Component({
  selector: 'app-horses-for-sale',
  templateUrl: './horses-for-sale.component.html',
  styleUrls: ['./horses-for-sale.component.css']
})
export class HorsesForSaleComponent implements OnInit {

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute) { }


  //horses = this.http.get('../../assets/horses.json')
 //arrHorses: string [];
 Videourl:string;
 arrHorses:horseDetailsData[]=[];


 ngOnInit () {
  debugger;
   this.http.get('../../assets/data/horses.json').subscribe(
     (data:any) => {
       // this.arrHorses = data as string [];	 // FILL THE ARRAY WITH DATA.
       this.arrHorses = data;	 // FILL THE ARRAY WITH DATA.

     },
     (err: HttpErrorResponse) => {
       console.log (err.message);
     }
   );


 }

 Setvideourl(slug)
 {


var getvideoLink=this.arrHorses.filter(i=>i.slug==slug)[0].video
this.Videourl=getvideoLink;


 }


}




/*
 	let horse = this.activatedRoute.snapshot.params.horse;
    console.log(horse);
*/
