<div class="ms-site-container">

  <app-nav-bar></app-nav-bar>

  <router-outlet></router-outlet>

  <app-footer></app-footer>
  <app-modals></app-modals>
  <app-contact-modal></app-contact-modal>

</div>

<app-side-menu></app-side-menu>

