<div class="index-1 intro-full-next pt-4">
  <div class="container">
    <div class="text-center mb-4">
      <h1 class="color-primary text-center" text-center>JUMPING</h1>
    </div>
    <div class="row">
      <div class="col-lg-12 text-justify">
        <p>Que Sera Farm has a jump circuit – a gentle course for ponies and beginners and a more challenging route for experienced riders. The circuit comprises a number of verticals with fills, two oxers and a jacob’s ladder.  All jumps are collapsible to conform with current standards. Only two riders are permitted on the circuit at any time and the rules shown below must be observed.</p>
        <p>Coaches are welcome to accompany riders. Riders are free to adjust jump heights but must restore all jumps to their original levels before leaving.</p>
      </div>
    </div>
  </div>
</div>

  <div class="container">
    <div class="row">
      <div class="col-md-6">
      	<h1 class="right-line color-primary">JUMP ARENA RULES</h1>
        <ol class="service-list list-unstyled">
          <li>Equestrian activities are dangerous and a Waiver must be signed by all riders prior to riding. They are available at the entrance to the arena.</li>
          <li>All participants must wear correct helmet and gear, preferably also with a vest.</li>
          <li>Maximum of TWO horses allowed on the course at any one time.</li>
          <li>No dogs are allowed in the arena.</li>
          <li>Children must be supervised.</li>
          <li>Gate MUST be kept closed in case a horse gets loose.</li>
          <li>SAFETY FIRST relates to all aspects of riding on Que Sera Farm. If help required please call 0408 477 799.</li>
        </ol>
      </div>
      <div class="col-md-6">
      	<h1 class="right-line color-primary">PRICES</h1>
        <ul class="">
    			<li>$20 per horse for one hour unlimited jumping. Use of the jump course is not included in any Que Sera agistment arrangement.</li>
    			<li>$10 for use of open arena</li>
    			<li>Use of indoor arena is $20</li>
          <li>Pricing for group bookings on request</li>
        </ul>
        <div class="card card-primary-inverse mb-4 mt-4">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12 text-center">
                <h4 class="mt-lg-0">Bookings are essential</h4>
                <p>Please use the booking button on the Que Sera <a href="https://www.facebook.com/pg/queserafarm/" target="_blank" style="color: #FFF;">Facebook Page</a> or call the Stable no: 0408 477 799</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-6 mb-4">
    <div class="row">
      <div class="col-lg-12">
          <div id="carousel-example-generic" class="ms-carousel ms-carousel-thumb carousel slide mb-4" data-ride="carousel">
            <div class="card card-relative">
                <div class="carousel-inner" role="listbox">
                    <div class="carousel-item active">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-01.jpg" atl="QUE SERA FARM - Jumping">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-02.jpg" atl="QUE SERA FARM - Jumping">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-04.jpg" atl="QUE SERA FARM - Jumping">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-05.jpg" atl="QUE SERA FARM - Jumping">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-07.jpg" atl="QUE SERA FARM - Jumping">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-08.jpg" atl="QUE SERA FARM - Jumping">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-09.jpg" atl="QUE SERA FARM - Jumping">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-10.jpg" atl="QUE SERA FARM - Jumping">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-03.jpg" atl="QUE SERA FARM - Jumping">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Jumps-04.jpg" atl="QUE SERA FARM - Jumping">
                    </div>
                </div>
                <a href="#carousel-example-generic" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary left carousel-control-prev" role="button" data-slide="prev"><i class="zmdi zmdi-chevron-left"></i></a>
                <a href="#carousel-example-generic" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary right carousel-control-next" role="button" data-slide="next"><i class="zmdi zmdi-chevron-right"></i></a>
            </div>
            <ol class="carousel-indicators carousel-indicators-tumbs carousel-indicators-tumbs-outside">
                <li data-target="#carousel-example-generic" data-slide-to="0" class="active">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-01_thumb.jpg" atl="QUE SERA FARM - Jumping">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="1">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-02_thumb.jpg" atl="QUE SERA FARM - Jumping">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="2">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-04_thumb.jpg" atl="QUE SERA FARM - Jumping">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="3">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-05_thumb.jpg" atl="QUE SERA FARM - Jumping">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="4">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-07_thumb.jpg" atl="QUE SERA FARM - Jumping">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="5">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-08_thumb.jpg" atl="QUE SERA FARM - Jumping">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="6">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-09_thumb.jpg" atl="QUE SERA FARM - Jumping">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="7">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-10_thumb.jpg" atl="QUE SERA FARM - Jumping">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="8">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-03_thumb.jpg" atl="QUE SERA FARM - Jumping">
                </li>
                <li data-target="#carousel-example-generic" data-slide-to="9">
                    <img class="" src="https://quesera.s3.amazonaws.com/pictures/Jumps-04_thumb.jpg" atl="QUE SERA FARM - Jumping">
                </li>
            </ol>
          </div>
       </div>
    </div>
  </div>

<app-map-frame></app-map-frame>

  