<div class="modal modal-primary" id="ContactModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog modal-lg animated zoomIn animated-3x" role="document">
    <div class="modal-content">
      <div class="modal-header d-block shadow-2dp no-pb">
        <button type="button" class="close d-inline pull-right mt-2" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="zmdi zmdi-close"></i></span></button>
        <div class="modal-title text-center">
          <img src="https://quesera.s3.amazonaws.com/logo_ribbon_250_black.png" alt="QueSera Farm" style="height: 35px;">
        </div>
      </div>
      <div class="modal-body">
        <form class="form-horizontal" [formGroup]="form" method="post" (ngSubmit)="onSubmit()">
        <input [formControl]="honeypot" class="hidden" type="hidden" />
          <fieldset class="container">
            <div class="form-group row">
              <label for="inputEmail" autocomplete="false" class="col-lg-2 control-label">Name</label>
              <div class="col-lg-9">
                <input type="text" class="form-control" [formControl]="name" required placeholder="Name">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail" autocomplete="false" class="col-lg-2 control-label">Email</label>
              <div class="col-lg-9">
                <input type="email" class="form-control" [formControl]="reply_to" required placeholder="Email">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail" autocomplete="false" class="col-lg-2 control-label">Subject</label>
              <div class="col-lg-9">
                <input type="text" class="form-control" [formControl]="subject" required  placeholder="Subject">
              </div>
            </div>
            <div class="form-group row">
              <label for="textArea" class="col-lg-2 control-label">Message</label>
              <div class="col-lg-9">
                <textarea class="form-control" rows="3" [formControl]="message" maxlength="256" placeholder="Your message..."></textarea>
              </div>
            </div>
            <div class="form-group row justify-content-end">
              <div class="col-lg-10">
                <button [disabled]="isLoading" class="btn btn-raised btn-primary" type="submit">Send Message</button>
              </div>
            </div>

            <div class="form-group row justify-content-end">
              <div class="col-lg-10">
                <div [ngClass]="!submitted? 'hidden' : 'visible'" class="center-text">
                  <span>{{responseMessage}}</span>
                </div> 
              </div>
            </div>

          </fieldset>
        </form>
    </div>
  </div>
</div>