<div
  id="contact"
  class="ms-hero-page-override ms-hero-img-farm2 ms-hero-bg-primary-dark"
>
  <div class="container">
    <div class="text-center pt-6 pb-6">
      <h1
        class="color-white center-block ms-site-title-lg mt-2 animated zoomInDown"
      >
        Get in Touch
      </h1>
    </div>
  </div>
</div>
<div class="container">
  <div class="card card-hero animated fadeInUp">
    <div class="card-body">
      <form
        class="form-horizontal"
        [formGroup]="form"
        method="post"
        (ngSubmit)="onSubmit()"
      >
        <input [formControl]="honeypot" class="hidden" type="text" />
        <fieldset class="container">
          <div class="form-group row">
            <label
              for="inputEmail"
              autocomplete="false"
              class="col-lg-2 control-label"
              >Name</label
            >
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                [formControl]="name"
                required
                placeholder="Name"
              />
              <span
                class="alert_box_error"
                *ngIf="isMyFormSubmiited && myContactForm.name.errors?.required"
              >
                Enter your name
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label
              for="inputEmail"
              autocomplete="false"
              class="col-lg-2 control-label"
              >Email</label
            >
            <div class="col-lg-9">
              <input
                type="email"
                class="form-control"
                [formControl]="reply_to"
                required
                placeholder="Email"
              />
              <span
                class="alert_box_error"
                *ngIf="
                  isMyFormSubmiited && myContactForm.reply_to.errors?.required
                "
              >
                Enter your email address
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label
              for="inputEmail"
              autocomplete="false"
              class="col-lg-2 control-label"
              >Subject</label
            >
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                [formControl]="subject"
                required
                placeholder="Subject"
              />
              <span
                class="alert_box_error"
                *ngIf="
                  isMyFormSubmiited && myContactForm.subject.errors?.required
                "
              >
                Enter your subject
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label for="textArea" class="col-lg-2 control-label">Message</label>
            <div class="col-lg-9">
              <textarea
                class="form-control"
                rows="3"
                [formControl]="message"
                maxlength="256"
                placeholder="Your message..."
              ></textarea>
              <span
                class="alert_box_error"
                *ngIf="
                  isMyFormSubmiited && myContactForm.message.errors?.required
                "
              >
                Enter your message
              </span>
            </div>
          </div>
          <div class="form-group row justify-content-end">
            <div class="col-lg-10">
              <button
                [disabled]="isLoading"
                class="btn btn-raised btn-primary"
                type="submit"
              >
                Send Message
              </button>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-lg-12">
              <div
                [ngClass]="!submitted ? 'hidden' : 'visible'"
                class="center-text"
              >
                <span
                  ><b>{{ responseMessage }}</b></span
                >
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
  <div class="card card-primary">
    <div class="row">
      <div class="col-xl-3 col-lg-4 col-md-5">
        <div class="card-body">
          <div class="mb-2">
            <img
              src="https://quesera.s3.amazonaws.com/logo_ribbon_250_black.jpg"
              alt="QueSera Farm"
            />
          </div>
          <address class="no-mb">
            <p>
              <i class="color-primary zmdi zmdi-pin mr-1"></i> 611 Biddaddaba
              Creek Rd,
            </p>
            <p>
              <i class="color-primary zmdi zmdi-pin mr-1"></i> Biddaddaba QLD
              4275
            </p>
            <p><i class="color-primary zmdi zmdi-pin mr-1"></i> Australia</p>
            <p>
              <i class="color-primary zmdi zmdi-email mr-1"></i>
              <a href="mailto:ks@gemini.com.au">ks@gemini.com.au</a>
            </p>
            <p>
              <i class="color-primary zmdi zmdi-phone mr-1"></i>0408 477 799
            </p>
          </address>
        </div>
      </div>
      <div class="col-xl-9 col-lg-8 col-md-7">
        <iframe
          width="100%"
          height="340"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d225409.50942640097!2d152.97167475202997!3d-28.02375496789733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3bda0bffaa8aa8a3!2sQue%20Sera%20Farm!5e0!3m2!1sen!2shk!4v1579073573841!5m2!1sen!2shk"
        ></iframe>
      </div>
    </div>
  </div>
</div>
