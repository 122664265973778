    <div class="container">
          <div class="row">
            <div class="col-lg mb-2 text-center">
                <h1 class="color-primary text-center">OUR FACILITIES</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
              <div class="ms-icon-feature">
                <div class="ms-icon-feature-icon">
                  <span class="ms-icon ms-icon-lg ms-icon-inverse"><img src="https://quesera.s3.amazonaws.com/horseshoe.png" alt="Que Sera Farm" style="width: 30px; height: 30px;"></span>
                </div>
                <div class="ms-icon-feature-content">
                  <h4 class="color-primary">COVERED ROUND YARD</h4>
                  <p class="text-justify">Covered Round Yard, fully rubber lined.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
              <div class="ms-icon-feature">
                <div class="ms-icon-feature-icon">
                  <span class="ms-icon ms-icon-lg ms-icon-inverse"><img src="https://quesera.s3.amazonaws.com/horseshoe.png" alt="Que Sera Farm" style="width: 30px; height: 30px;"></span>
                </div>
                <div class="ms-icon-feature-content">
                  <h4 class="color-primary">HORSE WALKER</h4>
                  <p class="text-justify">Horse Walker to accommodate four horses.  Reversible mechanism to allow horses to be exercised in both directions.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
              <div class="ms-icon-feature">
                <div class="ms-icon-feature-icon">
                  <span class="ms-icon ms-icon-lg ms-icon-inverse"><img src="https://quesera.s3.amazonaws.com/horseshoe.png" alt="Que Sera Farm" style="width: 30px; height: 30px;"></span>
                </div>
                <div class="ms-icon-feature-content">
                  <h4 class="color-primary">COVERED WASH BAYS AND TIE UP AREAS</h4>
                  <p class="text-justify">Completely covered. Hot and cold water available.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
              <div class="ms-icon-feature">
                <div class="ms-icon-feature-icon">
                  <span class="ms-icon ms-icon-lg ms-icon-inverse"><img src="https://quesera.s3.amazonaws.com/horseshoe.png" alt="Que Sera Farm" style="width: 30px; height: 30px;"></span>
                </div>
                <div class="ms-icon-feature-content">
                  <h4 class="color-primary">CAR, TRUCK & FLOAT PARKING, DAY YARDS</h4>
                  <p class="text-justify">Large car park with ample parking space for trucks and floats, 9 day yards along with wash bay and tie-ups.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
              <div class="ms-icon-feature">
                <div class="ms-icon-feature-icon">
                  <span class="ms-icon ms-icon-lg ms-icon-inverse"><img src="https://quesera.s3.amazonaws.com/horseshoe.png" alt="Que Sera Farm" style="width: 30px; height: 30px;"></span>
                </div>
                <div class="ms-icon-feature-content">
                  <h4 class="color-primary">BATHROOM FACILITIES</h4>
                  <p class="text-justify">Bathroom and shower facilities in the barn and three toilets outside.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
              <div class="ms-icon-feature">
                <div class="ms-icon-feature-icon">
                  <span class="ms-icon ms-icon-lg ms-icon-inverse"><img src="https://quesera.s3.amazonaws.com/horseshoe.png" alt="Que Sera Farm" style="width: 30px; height: 30px;"></span>
                </div>
                <div class="ms-icon-feature-content">
                  <h4 class="color-primary">LAUNDRY</h4>
                  <p class="text-justify">Rug and saddle pad laundering and repair service available</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
              <div class="ms-icon-feature">
                <div class="ms-icon-feature-icon">
                  <span class="ms-icon ms-icon-lg ms-icon-inverse"><img src="https://quesera.s3.amazonaws.com/horseshoe.png" alt="Que Sera Farm" style="width: 30px; height: 30px;"></span>
                </div>
                <div class="ms-icon-feature-content">
                  <h4 class="color-primary">WI-FI</h4>
                  <p class="text-justify">Limited WiFi coverage in barn and surrounding areas</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
              <div class="ms-icon-feature">
                <div class="ms-icon-feature-icon">
                  <span class="ms-icon ms-icon-lg ms-icon-inverse"><img src="https://quesera.s3.amazonaws.com/horseshoe.png" alt="Que Sera Farm" style="width: 30px; height: 30px;"></span>
                </div>
                <div class="ms-icon-feature-content">
                  <h4 class="color-primary">STAFF</h4>
                  <p class="text-justify">Que Sera staff are all experienced equestrian professionals. On-call vets, farriers, dentist and chiropractor available.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
              <div class="ms-icon-feature">
                <div class="ms-icon-feature-icon">
                  <span class="ms-icon ms-icon-lg ms-icon-inverse"><img src="https://quesera.s3.amazonaws.com/horseshoe.png" alt="Que Sera Farm" style="width: 30px; height: 30px;"></span>
                </div>
                <div class="ms-icon-feature-content">
                  <h4 class="color-primary">ON-SITE SADDLERY SHOP</h4>
                  <p class="text-justify">Que Sera Farm is home to ‘Equistore’, our on-site Saddlery Shop – come in and browse <a href="https://equistore.com.au">www.equistore.com.au</a></p>
                </div>
              </div>
            </div>
          </div>
          <div id="carousel-example-generic2" class="ms-carousel ms-carousel-thumb carousel slide" data-ride="carousel">
              <div class="card card-relative">
                  <div class="carousel-inner" role="listbox">
                      <div class="carousel-item active">
                          <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Web-114.jpg" atl="QUE SERA FARM">
                      </div>
                      <div class="carousel-item">
                          <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Web-125.jpg" atl="QUE SERA FARM">
                      </div>
                      <div class="carousel-item">
                          <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Web-108.jpg" atl="QUE SERA FARM">
                      </div>
                  </div>
                  <a href="#carousel-example-generic5" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary left carousel-control-prev" role="button" data-slide="prev"><i class="zmdi zmdi-chevron-left"></i></a>
                  <a href="#carousel-example-generic5" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary right carousel-control-next" role="button" data-slide="next"><i class="zmdi zmdi-chevron-right"></i></a>
              </div>
              <ol class="carousel-indicators carousel-indicators-tumbs carousel-indicators-tumbs-outside">
                  <li data-target="#carousel-example-generic2" data-slide-to="0" class="active">
                      <img class="" src="https://quesera.s3.amazonaws.com/pictures/Web-114_thumb.jpg" alt="">
                  </li>
                  <li data-target="#carousel-example-generic2" data-slide-to="1">
                      <img class="" src="https://quesera.s3.amazonaws.com/pictures/Web-125_thumb.jpg" alt="">
                  </li>
                  <li data-target="#carousel-example-generic2" data-slide-to="2">
                      <img class="" src="https://quesera.s3.amazonaws.com/pictures/Web-108_thumb.jpg" alt="">
                  </li>
              </ol>
          </div>
        </div>

<div class="intro-full color-white " id="home">
  <video id="intro-video" class="video-fluid embed-responsive embed-responsive-16by9 z-depth-1-half" playsinline autoplay loop muted>
    <source class="embed-responsive-item" src="https://quesera.s3.amazonaws.com/quesera_drone.mp4" type="video/mp4" />
  </video>

  <div class="intro-full-content index-1">
    <div class="container text-center">
      <img [src]="home.logo" alt="QueSera Farm">
    </div>
  </div>
</div>