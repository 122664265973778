<div class="container">
	<div class="row">
		<div class="col-md-12">
			<div class="card">
			  <video controls>
			    <source src="https://quesera.s3.amazonaws.com/Horse_All_Day_Event_Que_Sera Farm.mp4" type="video/mp4" />
			  </video>
			</div>
		</div>
	</div>
	<!--
	<div class="row">
		<div class="col-md-6">
			<div class="card">
			  <video controls>
			    <source src="https://quesera.s3.amazonaws.com/quesera_drone.mp4" type="video/mp4" />
			  </video>
			</div>
		</div>
		<div class="col-md-6">
			<div class="card">
			  <video controls>
			    <source src="https://quesera.s3.amazonaws.com/Que_Sera_Farm.mp4#t=0.9" type="video/mp4" />
			  </video>
			</div>
		</div>
	</div>
	-->
</div>