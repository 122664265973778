<div class="container">
	<div class="row">
		<div class="col-lg mb-2 text-center">
			<h1 class="color-primary text-center">SCHEDULE OF CHARGES</h1>
		    <p class="lead uppercase">(All prices are GST exclusive)</p>
		    <p class="bigFont">Download the GELS Schedule of Charges as a <a href="https://quesera.s3.amazonaws.com/GELS_SCHEDULE_OF_CHARGES_2023.pdf" target="_blank">PDF HERE</a></p>
		</div>
	</div>
	<div class="row">
		<div class="col-lg mb-4">
			<h2 class="color-primary right-line">AGISTMENT</h2>
            <p class="bigFont"><b>Categories 1) 2) and 3) entitle owners to use Indoor Arena, Horse Walker and Round Yard without additional charge except lighting</b> <small>&nbsp;&nbsp;(see arena use below)</small></p>
            <ol class="price-table-list bigFont">
              <li>Full Livery, stable and paddock with shelter $300 + GST weekly.</li>
              <li>Paddock with gated stable (4 complex facility) $260   +GST  weekly.</li>
              <li>Paddock with open shelter.  $240 + GST weekly.</li>
              <li>Open Agistment (Spelling) $130 + GST weekly</li>
              <li>Open Agistment (Spelling) shared paddock. $100 + GST weekly.</li>
              <li>Minimum Agistment Period:  14 days  (for less than 14 days please ask us for a quote).</li>
            </ol>
      <h2 class="color-primary">RACE HORSE SPELLING\AGISTMENT COMING SOON!</h2>
		</div>
	</div>
	<div class="row">
		<div class="col-lg mb-4">
			<h2 class="color-primary right-line">ARENA USE</h2>
            <ul class="price-table-list bigFont">
              <li><b>Agisted horses:</b><br>45 Minutes of daylight use per day in covered arena is allowed to owner or nominated rider per horse.<br><br></li>
              <li><b>Non-agisted horses:</b><br>Use of covered arena $25 + GST per horse per hour or part thereof.<br><br></li>
              <li><b>ALIGHTING (ALL HORSES):</b><br>Lighting for covered arena $10 + GST per horse, per hour, or part thereof.<br>(Two people together $5 + GST each).<br><br></li>
            </ul>
		</div>
	</div>
  <div class="row">
    <div class="col-lg mb-4">
      <h2 class="color-primary right-line">ARENA GROUP BOOKINGS</h2>
            <p class="bigFont"><b>Full Day:</b>  $300 + GST  Two consecutive days:  $500 + GST</p>
    </div>
  </div>
  <div class="row">
    <div class="col-lg mb-4">
      <h2 class="color-primary right-line">DAY YARDS</h2>
          <p class="bigFont">9 Day Yards are currently available.  Cost per day $10 + GST</p>
          <p class="bigFont">Wash and tie-up bays beside the day yards are available to all visitors free of charge.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-lg mb-4">
      <h2 class="color-primary right-line">JUMP ARENA</h2>
          <p class="bigFont">$25 per hour + GST.   Pricing for group bookings on request</p>
    </div>
  </div>
  <div class="row">
    <div class="col-lg mb-4">
      <h2 class="color-primary right-line">EQUITATION COURSE</h2>
          <p class="bigFont">$20 per hour + GST.  Pricing for group bookings on request.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-lg mb-4">
      <h2 class="color-primary right-line">TRAIL RIDE</h2>
          <p class="bigFont">$10 + GST. Per circuit. Pricing for group bookings on request.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-lg mb-4">
      <h2 class="color-primary right-line">ROUND YARD</h2>
          <p class="bigFont">$12 + GST per hour.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-lg mb-4">
      <h2 class="color-primary right-line">FLOAT PARKING</h2>
          <p class="bigFont">Daily:  $5 + GST.  Weekly $30 + GST</p>
    </div>
  </div>
  <div class="row">
    <div class="col-lg mb-4">
      <h2 class="color-primary right-line">CAMPING</h2>
          <p class="bigFont">$30 + GST per night.</p>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-lg mb-4">
      <p class="bigFont"><b>NOTE:</b><br>A HANDLING FEE OF $10 + GST WILL BE CHARGED FOR OUR WORK ASSOCIATED WITH COLLECTING HORSES FROM PADDOCKS AND RETURNING THEM FOLLOWING VISITS BY A FARRIER, VETERINARY PROFESSIONAL, CHIROPRACTOR OR EQUINE DENTIST.</p>
    </div>
  </div>
</div>
