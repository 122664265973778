<div class="container">
	<div class="row">
		<div class="col-lg mb-2 text-center">
			<h1 class="color-primary text-center">RACE HORSE SPELLING</h1>
		    <p class="bigFont">THE FIRST-CLASS FACILITY WITH PROFESSIONAL CARE AND SERVICES</p>
		</div>
	</div>
</div>

<section class="mt-2">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-xl-4 col-lg-4 col-md-4">
				<div class="card mt-4 card-primary">
					<div style="height: 200px; background-image: url(https://quesera.s3.amazonaws.com/horseSpelling_1.jpg); background-size: cover; background-position: center center">
						<br />
					</div>
					<div class="card-body pt-4 text-center" style="height: 180px;">
						<p class="lead color-primary">LARGE GRASSY PADDOCKS</p>
						<p>* PREMIUM QUALITY STALLION ULTRA RAIL BY DUNCAN EQUINE WITH SHELTERS</p>
					</div>
				</div>
			</div>
			<div class="col-xl-4 col-lg-4 col-md-4">
				<div class="card mt-4 card-primary">
					<div style="height: 200px; background-image: url(https://quesera.s3.amazonaws.com/horseSpelling_2.jpg); background-size: cover; background-position: center center">
						<br />
					</div>
					<div class="card-body pt-4 text-center" style="height: 180px;">
						<p class="lead color-primary">TWO FEEDS DAILY</p>
						<p>* CUSTOM OPTIONS AVAILABLE</p>
					</div>
				</div>
			</div>
			<div class="col-xl-4 col-lg-4 col-md-4">
				<div class="card mt-4 card-primary">
					<div style="height: 200px; background-image: url(https://quesera.s3.amazonaws.com/horseSpelling_3.jpg); background-size: cover; background-position: center center">
						<br />
					</div>
					<div class="card-body pt-4 text-center" style="height: 180px;">
						<p class="lead color-primary">DAILY SUPERVISION</p>
						<p>* REHABILITATION/MEDICATION SERVICES AVAILABLE</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<div class="container">
	<div class="row">
		<div class="col-lg mb-2 text-justify">
			<br>
			<hr>
			<br>
			<p class="bigFont">Over more than ten years Que Sera Farm has established an excellent reputation for the agistment and care of warmblood horses.</p>
			<p><br></p>
			<h1 class="color-primary text-center">We are now very pleased to offer spelling and rehabilitation services to the racing profession.</h1>
			<p><br></p>
			<p class="bigFont">Our spacious paddocks, on-demand water, safe fencing (with Duncan bounce back rail) and our experienced staff will keep thoroughbreds in prime condition. We have around fifty paddocks including several able to accommodate groups of horses, some paddocks are on undulating ground; most are level.</p>
			<p><br></p>
			<p class="bigFont">Que Sera has around 24 stables with rubber flooring, on demand water and lighting. Several stables are suitable for stallions. Ample wash bays and tie ups are situated beside each stable block.</p>
			<p><br></p>
			<p class="bigFont">We are extending our services to rehabilitation work for horses that need that additional care. Our stable supervisor Miss Lili Park, is very familiar with race horses and is able to administer injections and medication and arrange daily bandaging.</p>
			<p><br></p>
			<p class="bigFont">For those interested, our charges for spelling horses are $27 plus ST per day for a large grassy paddock with a shelter. Stabling is $40 plus GST per day.</p>
			<p><br></p>
			<p class="bigFont">Rehabilitation charges depend on the services required. A detailed quotation can be prepared.</p>
			<p><br></p>
			<p class="bigFont"><b>Should you wish to come and inspect the property please call Lili Park on 0455 683 055 to make an appointment. Alternatively, contact Michael Higgison on 0401 111 853 or Mrs Kay Sutherland on 0429 810 668.</b></p>
		</div>
	</div>
</div>



