import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facility-outdoor-arena',
  templateUrl: './facility-outdoor-arena.component.html',
  styleUrls: ['./facility-outdoor-arena.component.css']
})
export class FacilityOutdoorArenaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
