      <div class="material-background newBackground"></div>

      <div class="container-fluid">
        <div class="text-center mb-6">
          <h1 class="no-m ms-site-title color-white center-block ms-site-title-lg mt-2 animated zoomInDown animation-delay-5">Gallery</h1>
          <p class="lead lead-lg color-white text-center center-block mt-2 mb-4 mw-800 text-uppercase fw-300 animated fadeInUp animation-delay-7">Welcome to Que Sera</p>
        </div>
        <div class="row">
          <div class="col">
            <div class="row masonry-container">
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-02.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-02.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-4.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-4.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-05.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-05.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-08.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-08.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-11.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-11.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-16.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-16.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-18.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-18.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-22.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-22.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-25.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-25.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-29.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-29.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-30.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-30.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-33.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-33.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-35.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-35.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-37.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-37.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-38.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-38.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-40.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-40.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-42.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-42.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-43.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-43.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-44.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-44.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-46.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-46.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-47.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-47.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-48.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-48.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-49.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-49.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-50.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-50.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-52.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-52.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-53.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-53.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-54.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-54.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-55.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-55.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-56.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-56.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-58.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-58.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-59.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-59.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-60.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-60.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-61.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-61.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-62.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-62.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-63.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-63.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-65.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-65.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-66.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-66.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-67.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-67.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-68.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-68.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-70.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-70.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-71.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-71.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-72.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-72.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-73.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-73.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-75.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-75.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-77.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-77.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-78.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-78.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-81.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-81.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-82.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-82.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-83.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-83.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-84.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-84.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-85.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-85.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-86.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-86.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-87.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-87.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-89.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-89.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-90.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-90.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 

              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-95.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-95.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-97.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-97.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-98.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-98.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-99.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-99.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-100.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-100.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-101.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-101.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-102.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-102.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-103.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-103.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-104.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-104.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-105.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-105.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-107.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-107.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-108.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-108.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-109.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-109.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-111.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-111.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-112.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-114.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-116.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-116.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-117.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-117.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-118.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-118.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-119.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-119.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-120.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-120.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-121.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-121.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-122.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-122.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-124.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-124.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-lg-4 col-md-6 masonry-item wow fadeInUp">
                <div class="card wow zoomIn">
                  <div class="ms-thumbnail card-body p-05 ">
                    <div class="withripple zoom-img">
                      <a href="https://quesera.s3.amazonaws.com/gallery/Web-126.jpg" data-lightbox="gallery" data-title="QUE SERA FARM"><img src="https://quesera.s3.amazonaws.com/gallery/Web-126.jpg" alt="" class="img-fluid"></a>
                    </div>
                  </div>
                </div>
              </div> 


            </div>
          </div>
        </div>
      </div>
