<div class="index-1 intro-full-next pt-4">
  <div class="container">
    <div class="text-center mb-4">
      <h1 class="color-primary text-center" text-center>BOOKINGS</h1>
    </div>
  </div>
</div>

  <div class="container mt-6 mb-4">
    <div class="row">
      <div class="col-lg-12">
	<iframe src="https://app.squarespacescheduling.com/schedule.php?owner=24138096"  style="width: 100%; height: 1200px; overflow: auto;" title="Schedule Appointment" frameBorder="0"></iframe>
	<script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>

      </div>
    </div>
  </div>