import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

	nav = {
		logo: 'https://quesera.s3.amazonaws.com/logo_ribbon_250_black.jpg',
		logoScroll: 'https://quesera.s3.amazonaws.com/logo_ribbon_250_black.png'
	};

  constructor() { }

  ngOnInit(): void {
  }

}
