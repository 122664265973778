    <div class="container">
          <div class="row">
            <div class="col-lg mb-2 text-center">
                <h1 class="color-primary text-center">SAFE PADDOCKS & STABLES</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="ms-icon-feature">
                <div class="ms-icon-feature-icon">
                  <span class="ms-icon ms-icon-lg ms-icon-inverse"><img src="https://quesera.s3.amazonaws.com/horseshoe.png" alt="Que Sera Farm" style="width: 30px; height: 30px;"></span>
                </div>
                <div class="ms-icon-feature-content">
                  <h4 class="color-primary">TEN LARGE STABLES</h4>
                  <p class="text-justify">Concrete based with thick rubber and sawdust on top. Automatic waterers in each stable. Fans and insulation for relief in hot weather. Crush facility available for veterinary treatment</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 mb-2">
              <div class="ms-icon-feature">
                <div class="ms-icon-feature-icon">
                  <span class="ms-icon ms-icon-lg ms-icon-inverse"><img src="https://quesera.s3.amazonaws.com/horseshoe.png" alt="Que Sera Farm" style="width: 30px; height: 30px;"></span>
                </div>
                <div class="ms-icon-feature-content">
                  <h4 class="color-primary">35 PLUS PADDOCKS</h4>
                  <p class="text-justify">All are steel or securely fenced with electric protection. Each has automatic waterers and most with horse shelters</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 mb-2">
              <div class="ms-icon-feature">
                <div class="ms-icon-feature-icon">
                  <span class="ms-icon ms-icon-lg ms-icon-inverse"><img src="https://quesera.s3.amazonaws.com/horseshoe.png" alt="Que Sera Farm" style="width: 30px; height: 30px;"></span>
                </div>
                <div class="ms-icon-feature-content">
                  <h4 class="color-primary">FULLY LOCKABLE TACK ROOM</h4>
                  <p class="text-justify">For safe storage of saddlery</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 mb-2">
              <div class="ms-icon-feature">
                <div class="ms-icon-feature-icon">
                  <span class="ms-icon ms-icon-lg ms-icon-inverse"><img src="https://quesera.s3.amazonaws.com/horseshoe.png" alt="Que Sera Farm" style="width: 30px; height: 30px;"></span>
                </div>
                <div class="ms-icon-feature-content">
                  <h4 class="color-primary">FOUR ‘WALK IN WALK OUT’ STABLES WITH INDIVIDUAL PADDOCKS</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 mb-2">
              <div class="ms-icon-feature">
                <div class="ms-icon-feature-icon">
                  <span class="ms-icon ms-icon-lg ms-icon-inverse"><img src="https://quesera.s3.amazonaws.com/horseshoe.png" alt="Que Sera Farm" style="width: 30px; height: 30px;"></span>
                </div>
                <div class="ms-icon-feature-content">
                  <h4 class="color-primary">LARGE FOALING DOWN STABLE WITH PADDOCK</h4>
                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
          </div>
          <div id="carousel-example-generic5" class="ms-carousel ms-carousel-thumb carousel slide" data-ride="carousel">
              <div class="card card-relative">
                  <div class="carousel-inner" role="listbox">
                      <div class="carousel-item active">
                          <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Tie-Ups.jpg" atl="QUE SERA FARM">
                      </div>
                      <div class="carousel-item">
                          <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Stallion-Stables.jpg" atl="QUE SERA FARM">
                      </div>
                      <div class="carousel-item">
                          <img class="d-block img-fluid" src="https://quesera.s3.amazonaws.com/pictures/Stables.jpg" atl="QUE SERA FARM">
                      </div>
                  </div>
                  <a href="#carousel-example-generic5" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary left carousel-control-prev" role="button" data-slide="prev"><i class="zmdi zmdi-chevron-left"></i></a>
                  <a href="#carousel-example-generic5" class="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary right carousel-control-next" role="button" data-slide="next"><i class="zmdi zmdi-chevron-right"></i></a>
              </div>
              <ol class="carousel-indicators carousel-indicators-tumbs carousel-indicators-tumbs-outside">
                  <li data-target="#carousel-example-generic5" data-slide-to="0" class="active">
                      <img class="" src="https://quesera.s3.amazonaws.com/pictures/Tie-Ups_thumb.jpg" alt="">
                  </li>
                  <li data-target="#carousel-example-generic5" data-slide-to="1">
                      <img class="" src="https://quesera.s3.amazonaws.com/pictures/Stallion-Stables_thumb.jpg" alt="">
                  </li>
                  <li data-target="#carousel-example-generic5" data-slide-to="2">
                      <img class="" src="https://quesera.s3.amazonaws.com/pictures/Stables_thumb.jpg" alt="">
                  </li>
              </ol>
          </div>
        </div>