import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  form: FormGroup;
  name: FormControl = new FormControl('', [Validators.required]);
  subject: FormControl = new FormControl('', [Validators.required]);
  reply_to: FormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  message: FormControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(256),
  ]);
  honeypot: FormControl = new FormControl(''); // we will use this to prevent spam
  submitted: boolean = false; // show and hide the success message
  isLoading: boolean = false; // disable the submit button if we're loading
  responseMessage: string; // the response message to show to the user
  isMyFormSubmiited = false;

  constructor(private formBuilder: FormBuilder, private http: HttpClient) {
    this.form = this.formBuilder.group({
      name: this.name,
      subject: this.subject,
      reply_to: this.reply_to,
      message: this.message,
      //honeypot: this.honeypot
    });
  }

  ngOnInit(): void {}

  get myContactForm() {
    return this.form.controls;
  }

  onSubmit() {
    this.isMyFormSubmiited = true;
    if (this.form.status == 'VALID' && this.honeypot.value == '') {
      this.form.disable(); // disable the form if it's valid to disable multiple submissions
      var formData: any = new FormData();
      formData.append('name', this.form.get('name').value);
      formData.append('subject', this.form.get('subject').value);
      formData.append('reply_to', this.form.get('reply_to').value);
      formData.append('message', this.form.get('message').value);
      this.isLoading = true; // sending the post request async so it's in progress
      this.submitted = false; // hide the response message on multiple submits

      const jsonBody = JSON.stringify(this.form.value);
      console.log(jsonBody);

      this.http
        .post(
          'https://m5qnq0bsr6.execute-api.us-east-1.amazonaws.com/production/submit',
          jsonBody
        )
        .subscribe(
          //			this.http.post("https://7wtvswfbt3.execute-api.us-east-1.amazonaws.com/dev/users/create", { "body": "{\"name\":\"jonathan\",\"reply_to\":\"joforest@hotmail.com\",\"message\":\"this is the message\"}"}, { headers: {'Accept':'application/json; charset=utf-8','Content-Type':'application/json; charset=UTF-8'}}).subscribe(

          (response) => {
            // choose the response message

            console.log(response);
            //if (response['message']['MessageId'] != '') {
            if (response != '') {
              this.responseMessage =
                "Thanks for the message! I'll get back to you soon!";
            } else {
              this.responseMessage = 'Oops! Something went wrong...';
            }
            this.form.enable(); // re enable the form after a success
            this.submitted = true; // show the response message
            this.isLoading = false; // re enable the submit button
            this.form.reset();
            this.isMyFormSubmiited = false;
            // console.log(response['message']['MessageId']);
          },
          (error) => {
            this.responseMessage =
              'Oops! An error occurred... Reload the page and try again.';
            this.form.enable(); // re enable the form after a success
            this.submitted = true; // show the response message
            this.isLoading = false; // re enable the submit button
            this.form.reset();
            console.log(error);
          }
        );
    }
  }
}
